
export const MENU_ITEMS = [
  { label: "header.home", path: "#home" },
  { label: "header.solutions", path: "#solutions" },
  {
    label: "header.technologies",
    path: "#technologies",
  },
  { label: "contact.title", path: "#contact" },
];
