import React from "react";
import classNames from "classnames";
import { HashLink as Link } from "react-router-hash-link";
import { FormattedMessage } from "react-intl";
import { ThreeDots } from "react-loader-spinner";
import "./style.scss";

const Button = ({
  onClick,
  label,
  background,
  color,
  className = "",
  linkeButton = false,
  pathTo = "/",
  type = "button",
  disabled = false,
  target,
  isLoading,
}) => {
  return (
    <div className="button__container">
      <button
        style={{ backgroundColor: background, color }}
        onClick={onClick}
        className={classNames(["button", className])}
        type={type}
        disabled={disabled}
      >
        {linkeButton ? (
          <Link smooth to={pathTo} target={target}>
            <FormattedMessage id={label} />
          </Link>
        ) : isLoading ? (
          <ThreeDots
            visible={true}
            height="22"
            width="50"
            color="white"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        ) : (
          <FormattedMessage id={label} />
        )}
      </button>
    </div>
  );
};

export default Button;
