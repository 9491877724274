import {
  faCode,
  faObjectGroup,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";

export const CARDS_LIST = [
  {
    title: "services.development",
    subtitle: "service.developmentInfo",
    color: "#FF471F",
    icon: faCode,
    text: 'services.developmentText',
  },
  {
    title: "services.customizedSoft",
    subtitle: "service.customizedSoftInfo",
    color: "#FF471F",
    icon: faChartLine,
    text: 'services.customizedSoftText',
  },
  {
    title: "services.staffAugmentation",
    subtitle: "services.staffAugmentationInfo",
    color: "#FF471F",
    icon: faObjectGroup,
    text: 'services.staffAugmentationtext',
  },
];
