import React from "react";
import { FormattedMessage } from "react-intl";
import Card from "../../../../components/Card";
import { TECHNOLOGIES_LIST } from "./constants";
import "./style.scss";

const Technologies = () => {
  return (
    <section className="section-box technologies" id="technologies">
      <div className="technologies__title-container d-flex-column">
        <h2 className="section__title transition--underline">
        <FormattedMessage id="technologies.title"/>
        </h2>
        <p>
        <FormattedMessage id="technologies.text"/>
        </p>
      </div>
      <div className="technologies__cards-container wrap-center">
        {TECHNOLOGIES_LIST.map(({ label, img }) => {
          return <Card key={label} title={label} img={img} size="medium" imgSize="small"/>;
        })}
      </div>
    </section>
  );
};

export default Technologies;
