import react  from "../../../../assets/logo/technologies/react.png";
import typescript from "../../../../assets/logo/technologies/typescript.png";
import  next from "../../../../assets/logo/technologies/next-js.png";
import  postgres from "../../../../assets/logo/technologies/postgres.png";
import  mongo from "../../../../assets/logo/technologies/mongo.png";
import jest from "../../../../assets/logo/technologies/jest.png";
import testing from "../../../../assets/logo/technologies/testing-library.png";
import jira from "../../../../assets/logo/technologies/jira.png";
import node from "../../../../assets/logo/technologies/node.png";
import github from "../../../../assets/logo/technologies/git.png";
import sass from "../../../../assets/logo/technologies/sass.png";
import styled from "../../../../assets/logo/technologies/styled-components.png";
import material from "../../../../assets/logo/technologies/material-ui.png";
import ant from "../../../../assets/logo/technologies/ant.svg";
import graph from "../../../../assets/logo/technologies/graphql.png";
import aws from "../../../../assets/logo/technologies/aws-amazon.png";
import reactQuery from "../../../../assets/logo/technologies/react-query.png";
import jotai from "../../../../assets/logo/technologies/jotai.png";

export const TECHNOLOGIES_LIST = [
  {
    label: "React",
    img: react,
    id: 1,
  },
  {
    label: "Typescript",
    img: typescript,
    id: 2,
  },
  {
    label: "next",
    img: next,
    id: 3,
  },
  {
    label: "Mongo DB",
    img: mongo,
    id: 4,
  },
  {
    label: "Postgres",
    img: postgres,
    id: 5,
  },

  {
    label: "Jest",
    img: jest,
    id: 6,
  },
  {
    label: "Testing library",
    img: testing,
    id: 7,
  },
  {
    label: "Jira",
    img: jira,
    id: 8,
  },
  {
    label: "NodeJS",
    img: node,
    id: 11,
  },
  {
    label: "Github",
    img: github,
    id: 12,
  },
  {
    label: "Sass",
    img: sass,
    id: 13,
  },
  {
    label: "Styled Components",
    img: styled,
    id: 14,
  },
  {
    label: "Material UI",
    img: material,
    id: 15,
  },
  {
    label: "Ant Design",
    img: ant,
    id: 16,
  },
  {
    label: "GraphQL",
    img: graph,
    id: 17,
  },
  {
    label: "AWS",
    img: aws,
    id: 18,
  },
  {
    label: "Jotai",
    img: jotai,
    id: 19,
  },
  {
    label: "Rect-Query",
    img: reactQuery,
    id: 20,
  },
];
