import React from "react";
import LayoutWrapper from "../../components/LayoutWrapper";
import Navbar from "../../components/Navbar";

import Home from "../Home";

function Main() {
  return (
    <LayoutWrapper>
      <Navbar />
      <Home />
    </LayoutWrapper>
  );
}

export default Main;
