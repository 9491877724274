import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { toast, ToastContainer } from "react-toastify";
import img from "../../../../assets/images/contact.avif";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import TextArea from "../../../../components/TextArea";
import {
  checkIsEmail,
  INITIAL_FORM_VALUES,
  NOTIFICATION_EP,
  POST_CONFIG,
} from "./constants";
import "./styles.scss";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const [formData, setFormData] = useState(INITIAL_FORM_VALUES);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const isDisabled =
    !formData.name || !formData.email || !formData.message || emailError;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(NOTIFICATION_EP, {
        ...POST_CONFIG,
        body: JSON.stringify(formData),
      });

      if (response) {
      setFormData(INITIAL_FORM_VALUES);
      toast.success(<FormattedMessage id="contact.email.success" />, {
        position: "top-right",
        className: "toast-success",
      });
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(<FormattedMessage id="contact.email.error" />, {
        position: "top-right",
      });
      setIsLoading(false);
    }
  };

  const handleChange = (value, key) => {
    const isInvalidEmail = checkIsEmail(value, key);
    setEmailError(isInvalidEmail);
    setFormData({ ...formData, [key]: value });
  };

  return (
    <section className="contact-us" id="contact">
      <ToastContainer />
      <div className="contact-us__container">
        <div className="contact-us__image-container">
          <img src={img} alt="contact" loading="lazy" />
        </div>
        <div className="contact-us__form-container d-flex">
          <div className="d-flex-column">
            <h2 className="section__title">
              <FormattedMessage id="contact.title" />
            </h2>
            <h3>
              <FormattedMessage id="footer.contactSubtitle" />
            </h3>
            <form className="contact-us__form" onSubmit={handleSubmit}>
              <div className="contact-us__form d-flex-column">
                <Input
                  type="text"
                  placeholder="contact.name"
                  label="contact.name"
                  onChange={(e) => handleChange(e.target.value, "name")}
                  value={formData.name}
                  name="name"
                />
                <Input
                  type="text"
                  placeholder="contact.email"
                  label="contact.email"
                  onChange={(e) => handleChange(e.target.value, "email")}
                  value={formData.email}
                  name="email"
                />
                <span className="contact-us__message_warging">
                  {emailError && (
                    <FormattedMessage id="contact.email.warning" />
                  )}
                </span>
                <TextArea
                  placeholder="contact.phone"
                  label="contact.phone"
                  onChange={(e) => handleChange(e.target.value, "message")}
                  value={formData.message}
                  name="message"
                />
              </div>

              <div className="d-flex contact-us__button-container">
                <Button
                  label="contact.button"
                  className={`action__button ${isDisabled ? "disabled" : ""}`}
                  type="submit"
                  disabled={isDisabled}
                  isLoading={isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
