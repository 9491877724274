import React from "react";
import Footer from "./components/Footer";
import InitialInfo from "./components/InitialInfo";
import Services from "./components/Services";
import Technologies from "./components/Technologies";

import ChallangesInfo from './components/ChallengesInfo/index';
import SolutionSteps from "./components/SolutionSteps";
import ContactUs from "./components/ContactUs";

const Home = () => {
  return (
    <div className="home" id="home">
      <InitialInfo />
      <ChallangesInfo/>
      <SolutionSteps/>
      <Services />
      <Technologies />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;

