export const NOTIFICATION_EP =
  "https://email-sender-mocha.vercel.app/send-email";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const checkIsEmail = (value, key) => {
  if (key === "email") return !EMAIL_REGEX.test(value);
  return false;
};

export const POST_CONFIG = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
};

export const INITIAL_FORM_VALUES = { name: "", email: "", message: "" };
