import { BrowserRouter } from "react-router-dom";
import Main from "./views/Main";

import "./style/main.scss";

function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <Main />
      </div>
    </BrowserRouter>
  );
}

export default App;
