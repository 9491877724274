import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import { BOOK_A_CALL_PATH } from "../../../../constants";
import { CARDS_LIST } from "./constants";
import "./style.scss";

const Services = () => {

  return (
    <section className="section-box services" id="services">
      <div className="services__title-container">
        <h2 className="section__title transition--underline">
          <FormattedMessage id="services.title" />
        </h2>
        <p>
          <FormattedMessage id="services.text" />
        </p>
      </div>
      <div className="services__cards-container wrap-center">
        {CARDS_LIST.map(({ title, subtitle, color, icon, text }) => {
          return (
            <Card
              isFlipCard
              key={title}
              title={title}
              subtitle={subtitle}
              color={color}
              icon={icon}
              text={text}
            />
          );
        })}
      </div>
      <div className="services__button-container d-flex">
        <Button
          label="initialInfo.button"
          linkeButton
          className="action__button"
          pathTo={BOOK_A_CALL_PATH}
          target="_blank"
        />
      </div>
    </section>
  );
};

export default Services;
