import { faUsersViewfinder, faDownLeftAndUpRightToCenter } from "@fortawesome/free-solid-svg-icons";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
 
 export const CHALLENGES = [
    {
      title: "challenge.firstCard",
      text: 'challenge.firstCardText',
      icon: faDownLeftAndUpRightToCenter,
      id: 1,
    },
    {
      title: "challenge.secondCard",
      text: "challenge.secondCardText",
      icon: faUsersViewfinder,
      id: 2,
    },
    {
      title: "challenge.thirdCard",
      text: "challenge.thirdCardText",
      icon: faHandshake,
      id: 3,
    },
  ];