import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import logo from "../../../../assets/logo/04-LOGO SAPIENSY.svg";
import "./style.scss";
import { FormattedMessage } from "react-intl";
import { HashLink as Link } from "react-router-hash-link";
import {
  EMAIL,
  LINKEDIN_PATH,
  TELEPHONE,
  WHATSAPP_PATH,
} from "../../../../constants";

const Footer = () => {
  return (
    <footer className="footer" id="footer">
      <div className="footer__elements-container">
        <div className="footer__logo-container">
          <img className="footer__image" src={logo} alt="image_logo_sapiensy" />
        </div>
        <div className="footer__list-container flex">
          <div className="footer__list">
            <h4>
              <FormattedMessage id="footer.contact" />
            </h4>
            <ul>
              <li className="footer__icon">
                <Link
                  smooth
                  to={WHATSAPP_PATH}
                  target="_blank"
                  className="footer__link"
                >
                  <FontAwesomeIcon icon={faWhatsapp} /> {TELEPHONE}
                </Link>
              </li>
              <li>{EMAIL}</li>
            </ul>
          </div>
          <div className="footer__list flex align-center">
            <h4>
              <FormattedMessage id="footer.follow" />
            </h4>
            <ul>
              <li className="flex align-self-center">
                <Link
                  smooth
                  to={LINKEDIN_PATH}
                  target="_blank"
                  className="footer__icon"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer__rigths-container">
        <span>
          <FormattedMessage id="footer.copyrigth" />
        </span>
      </div>
    </footer>
  );
};

export default Footer;
