import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./style.scss";

const Card = ({
  title,
  subtitle,
  icon,
  color,
  text,
  isFlipCard,
  img,
  size = "default",
  imgSize = "default",
}) => {
  return (
    <div className={`card card--${size}`}>
      <div
        className={classNames([
          "card-inner",
          {
            "card-inner--flip": isFlipCard,
          },
        ])}
      >
        <div className="card-default card-front">
          {icon ? <FontAwesomeIcon icon={icon} color={color} /> : null}
          {img ? (
            <img
              className={`card__img card__img--${imgSize}`}
              src={img}
              alt={`technology-${title}`}
              loading="lazy"
            />
          ) : null}
          <h3 className="card__label">
            <FormattedMessage id={title} />
          </h3>
          <p>{subtitle ? <FormattedMessage id={subtitle} /> : null}</p>
        </div>
        {isFlipCard ? (
          <div className="card-default card-back">
            <h3>
              <FormattedMessage id={title} />
            </h3>
            <p>{text && <FormattedMessage id={text} />}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Card;
