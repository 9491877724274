import React from "react";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CHALLENGES } from "./constants";
import Button from "../../../../components/Button";
import { BOOK_A_CALL_PATH } from "../../../../constants";

import "./style.scss";

const ChallangesInfo = () => {

  return (
    <section className="section-box challenge-info d-flex">
      <div className="challenge-info__container">
        <div className="challenge-info__action-content d-flex-column">
          <div className="challenge-info__title-container d-flex-column">
              <p>
                <FormattedMessage id="challenge.title" />
              </p>
              <h2 className="section__title">
                <FormattedMessage id="challenge.subtitle" />
              </h2>
          </div>
          <Button
            label="initialInfo.button"
            className="action__button"
            linkeButton
            pathTo={BOOK_A_CALL_PATH}
            target="_blank"
          />
        </div>
        <div className="challenge-info__steps_content d-flex">
          {CHALLENGES.map(({ title, text, id, icon }) => (
            <div key={id} className="challenge-info__step-item d-flex-column">
              <div className="challenge-info__icon">
                <FontAwesomeIcon icon={icon} />
              </div>
              <h3>
                <FormattedMessage id={title} />
              </h3>
              <p>
                <FormattedMessage id={text} />
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ChallangesInfo;
