import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/logo/04-LOGO SAPIENSY.svg";
import { Context } from "../LenguageWrapper";
import Menu from './Menu';
import MenuMobile from "./menuMobile";
import "./style.scss";

const Navbar = () => {
  const { changeLanguage } = useContext(Context);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);
  }, []);
  const handleLenguage = (e) => {
    changeLanguage(e);
  };

  return (
    <header className="navbar container d-flex">
      <div className="navbar__logo-container d-flex">
        <img className="nabvar__image" src={logo} alt="image_logo_sapiensy" />
      </div>
      {screenWidth > 980 ? (
        <Menu handleLenguage={handleLenguage}/>
      ) : (
        <MenuMobile handleLenguage={handleLenguage} />
      )}
    </header>
  );
};

export default Navbar;
