import React from "react";
import classNames from "classnames";
import "./style.scss";
import { FormattedMessage } from "react-intl";

const TextArea = ({
  label,
  errors,
  inputClassName = "",
  handleChange,
  placeholder,
  name,
  ...props
}) => {
  return (
    <>
      <label for={name}>
        <FormattedMessage id={label} />
      </label>
      <textarea
        className={`m-bottom-1 ${classNames("textArea", inputClassName, {
          error: errors,
        })}`}
        onChange={handleChange}
        {...props}
        id={name}
      />
      {errors && <p className="input__text-error">{errors}</p>}
    </>
  );
};

export default TextArea;
