import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { FormattedMessage } from "react-intl";
import spain from "../../../assets/images/spain.png";
import uk from "../../../assets/images/uk.png";
import { MENU_ITEMS } from './../constants';

const Menu = ({ handleLenguage, className = "navbar__menu-container" }) => {
  return (
    <div className={`${className} flex align-center`}>
      <ul>
        {MENU_ITEMS.map(({ label, path }) => (
          <li key={label} className="navbar__menu-element ">
            <Link smooth to={path} className="transition--underline">
              <FormattedMessage id={label} />
            </Link>
          </li>
        ))}
      </ul>

      <ul className="navbar__flags-container">
        <li onClick={handleLenguage} className="navbar__flag">
          <img src={spain} alt="spain__flag" value="en-es" />
        </li>
        <li onClick={handleLenguage} className="navbar__flag">
          <img src={uk} alt="uk__flag" value="en-us" />
        </li>
      </ul>
    </div>
  );
};

export default Menu;
