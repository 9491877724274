import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Menu from "./../Menu";

const MenuMobile = ({ handleLenguage }) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const handleOpenMenu = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  return (
    <>
      <div className="navbar__hamburguer-container">
        <FontAwesomeIcon icon={faBars} onClick={handleOpenMenu} />
      </div>
      {isNavExpanded && (
        <Menu
          handleLenguage={handleLenguage}
          className="navbar__menu-expanded"
        />
      )}
    </>
  );
};

export default MenuMobile;
