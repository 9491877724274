import React from "react";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./../../../../components/Button";
import img from "../../../../assets/images/startup.avif";
import { SOLUTION_STEPS } from "./constants";

import "./style.scss";
import { BOOK_A_CALL_PATH } from "../../../../constants";

const SolutionSteps = () => {
  return (
    <section className="section-box solution-steps d-flex" id="solutions">
      <div className="solution-steps__container">
        <div className="solution-steps__steps d-flex">
          <div className="solution-steps__info-container d-flex-column">
            <h2 className="transition--underline section__title reverse">
              <FormattedMessage id="steps.title" />
            </h2>
            <ul className="d-flex-column">
              {SOLUTION_STEPS.map(({ id, title, text, icon }) => (
                <li key={id} className="d-flex-column">
                  <div className="solution-steps__title d-flex">
                    <FontAwesomeIcon icon={icon} />
                    <span>
                      <FormattedMessage id={title} />
                    </span>
                  </div>
                  <p>
                    <FormattedMessage id={text} />
                  </p>
                </li>
              ))}
            </ul>
            <Button
              label="initialInfo.button"
              pathTo={BOOK_A_CALL_PATH}
              target="_blank"
              linkeButton
              className="action__button"
            />
          </div>
        </div>
        <div className="solution-steps__image-container">
          <img src={img} alt="startup" loading="lazy" />
        </div>
      </div>
    </section>
  );
};

export default SolutionSteps;
