import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../../../components/Button";
import { BOOK_A_CALL_PATH } from "../../../../constants";
import "./style.scss";

const InitialInfo = () => (
  <section className="initial-info">
    <div className="initial-info__container">
      <div className="initial-info__text-container">
        <h2 className="initial-info__title">
          <FormattedMessage id="initialInfo.title" />
        </h2>
        <Button
          label="initialInfo.button"
          pathTo={BOOK_A_CALL_PATH}
          target="_blank"
          linkeButton
          className="action__button"
        />
      </div>
    </div>
  </section>
);

export default InitialInfo;
