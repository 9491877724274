
import { fa1, fa2, fa3 } from "@fortawesome/free-solid-svg-icons";

export const SOLUTION_STEPS = [
    {
      title: "steps.oneTitle",
      text: 'steps.one',
      icon: fa1,
      id: 1,
    },
    {
      title: "steps.twoTitle",
      text: "steps.two",
      icon: fa2,
      id: 2,
    },
    {
      title: "steps.threeTitle",
      text: "steps.three",
      icon: fa3,
      id: 3,
    },
  ];